<template>
  <footer class="content container-fluid position-relative mb-4 px-lg-10">
    <div class="d-flex justify-content-center align-items-center gap-8 bottom-0 w-9">
      <p class="text-secondary mb-2 text-align-end">
        Feito com <img src="/svg/icons/heart-icon.svg" alt="" style="height: 1rem" />
        pela Winx. Por um RH mais humano, inteligente e preditivo.
      </p>
    </div>
  </footer>
</template>
