<template>
  <div id="offcanvasMenu" class="offcanvas offcanvas-end" tabindex="-1" aria-labelledby="offcanvasMenuLabel">
    <div class="offcanvas-header align-items-start">
      <div>
        <h3 id="offcanvasMenuLabel">Menu</h3>
      </div>
      <button
        ref="close"
        type="button"
        class="btn-close"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
        style="background: none; opacity: 1"
      >
        <img ref="close" aria-label="Close" src="/svg/iconsax/close-circle.svg" alt="" />
      </button>
    </div>
    <div class="offcanvas-body">
      <div class="nav nav-vertical">
        <div class="nav-item">
          <nuxt-link :to="{ name: 'index' }" class="nav-link ps-2" active-class="active" @click="close.click()">
            {{ $t('menus.header.home') }}
          </nuxt-link>
        </div>

        <div v-if="currentDiagnosis" class="nav-item">
          <nuxt-link :to="currentDiagnosis" class="nav-link ps-2" active-class="active" @click="close.click()">
            {{ $t('menus.header.surveys') }}
          </nuxt-link>
        </div>
        <!-- INÍCIO DA SIDEBAR -->
        <div class="sidebar-content">
          <div id="diagnostic-engagement-menu-items"></div>
          <div id="diagnostic-culture-menu-items"></div>
        </div>
        <!-- FIM DA SIDEBAR-->
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { useAuthUser } from '#imports';
  import { useDiagnosisStore } from '~/stores/diagnosisStore';

  const diagnosisStore = useDiagnosisStore();

  const currentDiagnosis = computed(() => diagnosisStore.currentDiagnosis);

  const close = ref(null);

  const { logout } = useAuth();

  const user = useAuthUser();
</script>
