<template>
  <div>
    <Head>
      <Title>Winx - {{ $t($route.meta?.title || 'welcome') }}</Title>
    </Head>
    <Body class="pr-0">
      <LayoutHeader />
      <main id="content" role="main" class="main">
        <HsNotify />
        <div
          class="position-fixed d-none d-md-block top-0 end-0 bg-img-start h-100"
          :style="{ 'background-image': 'url(' + $s3Shared('svg/patterns/vertical_blue.svg') + ')' }"
        />
        <div class="content position-relative d-grid w-full">
          <slot />
        </div>
      </main>
      <!-- ========== END MAIN CONTENT ========== -->

      <!-- Footer -->
      <LayoutFooter />
      <!-- End Footer -->

      <!-- Menu -->
      <LayoutHeaderMenu />
      <!-- End Menu -->

      <!-- List Company -->
      <LazyLayoutHeaderListCompany v-if="$user.value?.isSuper" />
      <!-- End List Company -->
    </Body>
  </div>
</template>

<script lang="ts" setup>
  import { useAsyncData } from '#imports';
  import { useDefaultStore } from '~/stores/defaultStore';

  const store = useDefaultStore();

  await useAsyncData('init-dash', () => store.init());
</script>

<style scoped>
  .bg-img-start {
    background-repeat: no-repeat;
    background-position: left;
    z-index: 0;
    right: 0;
    width: 20%;
  }

  .bg-smile {
    background-repeat: no-repeat;
    background-size: auto;
    background-position: right;
    z-index: 0;
    left: 0;
    top: -15rem;
  }

  body {
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding-right: 0 !important;
  }

  main {
    flex: 1 0 auto;
  }
</style>
